// |------------------------------------------------------
// |------------------------------------------------------
// | Navigation
// |------------------------------------------------------
// |------------------------------------------------------

.navigation
	margin: 0
	display: flex
	flex-direction: column
	@include touch
		width: 100%

	&--primary
		margin-top: spacing()
		@include desktop
			margin-top: auto

	&--secondary
		margin-top: auto
		@include touch
			padding-top: spacing()
			padding-bottom: spacing()
			//margin-top: spacing()
			border-top: 1px solid rgba($grey-lighter, 0.5)

	&__link
		display: inline-block
		padding: spacing(2)
		border-radius: 5px
		font-weight: $weight-medium
		transition: $transition-base
		width: 100%
		&:hover
			color: $primary
			-webkit-text-fill-color: $primary
			background: transparent
		@include desktop
			opacity: 0.8
			margin-bottom: spacing(2)
		@include touch
			padding: spacing(3) spacing()
			font-size: 20px
		.svg,
		.fill
			fill: $white
		.stroke
			stroke: $white
		.place,
		.screen,
		.template
			svg
				width: 20px
				left: 3px
		.place
			svg
				left: 3px
		&:hover
			opacity: 1
			// background: rgb($white, 0.4)
		&.is-active,
		&.router-link-active
			opacity: 1
			background: $white-ter
			font-weight: $weight-semibold
			@include touch
				background: transparent
				color: $primary
				-webkit-text-fill-color: $primary
			.svg,
			.fill
				fill: $secondary
			.stroke
				stroke: $secondary
		&--logout
			color: $secondary
			@include touch
				color: $danger
				-webkit-text-fill-color: $danger
			.svg
				opacity: 1
			.fill
				fill: $secondary

.icon-button
	display: none
	height: 24px
	width: 24px
	cursor: pointer
	@include touch
		display: block
