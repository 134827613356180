// |------------------------------------------------------
// |------------------------------------------------------
// | Helpers
// |------------------------------------------------------
// |------------------------------------------------------

// Reset
// *
// 	margin: 0
// 	padding: 0
// 	box-sizing: border-box

	// dark-mode
	// background: $black-ter


*:focus
	outline: none

body,
html
	background: $white-ter
	letter-spacing: -0.015em

// $themes: (
//         default: (),
//         dark: (
// 					background:$black-ter
// 					color: $white-ter
// 				),
// )

html,
body,
.scroll,
.modal-card-body

	&::-webkit-scrollbar-track
		background: $white-bis
		// background: linear-gradient(to right, var(--scroll-track-color) 0%, var(--scroll-track-color) 30%, var(--scroll-color) 60%, var(--body-bg-color) 61%, var(--body-bg-color) 100%)

	&::-webkit-scrollbar
		width: 7px

	&::-webkit-scrollbar-thumb
		background-color: $grey-lighter
		border-radius: 0.313rem
		// border: 1px solid white
		// border-radius: $radius

::selection
	background-color: $primary
	color: $white

.debug
	background: $danger
	color: $white
	padding: 3px 10px
	border-radius: $radius
	&.fixed
		position: fixed
		top: 10px
		right: 10px
		z-index: 999

.no-events
	pointer-events: none

.no-select
	user-select: none

.pointer
	cursor: pointer

.hidden
	display: none
	opacity: 0

.multiselect__option span,
.is-ellipsis
	white-space: nowrap
	overflow: hidden
	text-overflow: ellipsis

.pointer
	cursor: pointer

.help
	cursor: help

.rotate-180,
.rotate-180 svg
	transform: rotate(180deg)

.overlay
	content: ''
	position: absolute
	top: 0
	left: 0
	right: 0
	bottom: 0
	opacity: 0.6
	border-radius: inherit
	z-index: 10
	background: $primary

.gradient-text
	display: inline-block
	background: -webkit-linear-gradient(320deg, $secondary, $tertiary)
	-webkit-background-clip: text
	-webkit-text-fill-color: transparent
	&::selection
		background: -webkit-linear-gradient($white, $white-ter)

.gradient-background
	background: linear-gradient(to right, $secondary, $tertiary)

.svg-gradient-start
	stop-color: $secondary

.svg-gradient-end
	stop-color: $tertiary

.mb-5-5
	margin-bottom: 2rem

// Positions
$positions: relative, absolute, fixed
@each $position in $positions
	.is-#{$position}
		position: $position

// Font Weight
$families: ('thin': $weight-thin, 'light': $weight-light, 'regular': $weight-regular, 'medium': $weight-medium, 'semibold': $weight-semibold, 'bold': $weight-bold)
@each $name, $family in $families
	.is-#{$name}
		font-weight: $family !important

// Align
$aligns: center, left, right, justify
@each $align in $aligns
	.text-#{$align}
		text-align: $align

// Text Transform
$transforms: capitalize, uppercase, lowercase, none
@each $transform in $transforms
	.text-#{$transform}
		text-transform: $transform

// Rating Colors
$colors: ('light': #95F3FA, 'medium': #4A9FF4, 'dark': #1248C9)
@each $name, $color in $colors
	.color-#{$name}
		color: $color!important

$backgrounds: ('light': #95F3FA, 'medium': #4A9FF4, 'dark': #1248C9)
@each $name, $background in $backgrounds
	.background-#{$name}
		color: $white!important
		background: $background!important
