// |------------------------------------------------------
// |------------------------------------------------------
// | Dashboard
// |------------------------------------------------------
// |------------------------------------------------------

.page .dashboard
	$blocks-height: calc(100vh - 130px)
	$panel-height: calc(100vh - 300px)
	position: relative
	min-height: $blocks-height
	&.animated
		opacity: 0
		animation: opacity 0.6s ease-out 1s forwards
	.is-size-5
		letter-spacing: -0.03em
	.carousel-list
		width: calc(100% + 20px)
		margin-left: -10px
		.carousel-slide
			border: 0
	&__dashboard
		display: flex
		justify-content: space-between
		align-items: center
		.b-skeleton
			margin: 0
		&.placeholder
			margin: 0 -10px
	&__title
		color: $grey-dark
		line-height: 1.1
		text-align: center
		letter-spacing: -0.04em
		@include mobile
			text-align: left
	&__select
		position: relative
		display: inline-block
		margin-bottom: 5px
		.icon
			position: absolute
			top: 12px
			right: 11px
			opacity: 0.4
		&:hover
			.icon
				opacity: 1
		select
			cursor: pointer
			background: $white
			border: 1px solid $grey-lighter
			&:hover
				//background: $grey-lightest
				color: $black
				border-color: $grey-light
			option
				text-align: left
				padding: 0
				font:
					weight: $weight-light
					size: 18px
		select,
		&__inner
			text-align: center
			color: $grey-dark
			border-radius: 5px
			padding: 8px 40px 7px
			appearance: none
			-webkit-appearance: none
			font:
				weight: $weight-semibold
				size: 18px
			transition: all 0.1s ease-out
	&__box
		border-radius: 5px
		font-size: 3rem
		// max-width: 90%
		margin: 0 spacing(2)
		text-align: center
		background: $white
		@include desktop
			margin: 0 auto
			max-width: 90%
		&.placeholder
			width: calc(20% - 20px)
			min-height: 145px
			display: flex
			justify-content: center
			align-items: center
			flex-direction: column
			.b-skeleton-item
				margin: 5px auto
		&__value
			display: flex
			justify-content: center
			align-items: center
			font-size: 1.3em
			padding: 0 10px
			color: $secondary
			letter-spacing: -0.06em
			font:
				weight: $weight-semibold
			@extend .gradient-text
			small
				// color: $grey-light
				font:
					size: 55%
					weight: $weight-light
				position: relative
				top: 4px
				left: -1px
		&__type
			color: $tertiary
			display: block !important
			margin-top: -10px
			@extend .gradient-text
			font:
				size: 1rem
				weight: $weight-medium
	&__panel
		margin-top: 0
		background: $white
		padding: spacing()
		border-radius: 5px
		height: 30vh
		@include desktop
			height: auto
			min-height: calc(100vh - 300px)
		.external
			cursor: pointer
			margin-top: 5px
			margin-right: 10px
			.fill
				fill: $secondary
			&:hover
				.fill
					fill: $tertiary
		.screens
			display: flex
			justify-content: space-between
			flex-wrap: wrap
			flex-direction: column
			@include desktop
				flex-direction: row
			img
				width: 100%
				border-radius: $radius
				margin-bottom: 10px
				max-height: 100%
				@include desktop
					width: calc(50% - 10px)
		.tabs ul
			display: none
		.tab-content
			padding: 0
	&__preview
		height: auto
		// min-height: 30vh
		.tab-item
			display: flex
			justify-content: flex-start
			flex-direction: column
			@include desktop
				flex-direction: row
		.stats
			float: right
			@include desktop
				width: 40%
		.iframe
			margin: 0 0 20px 0
			padding: 0
			border: 0
			width: 100%
			height: 285px
			border: 3px solid $white-ter
			border-radius: 5px
			@include desktop
				width: 60%
				margin: 0
		.box
			min-width: 230px
			margin: 0 0 20px 0
			// background: $white-ter
			border: 3px solid $white-ter
			@include desktop
				margin: 0 0 25px 25px
		.type
			small
				display: block
		img
			margin: 0 !important
	&__list
		padding: spacing(2)
		max-height: $panel-height
		overflow-y: auto
		.b-skeleton-item
			transition: $transition-base
		li
			min-height: 57px
			margin-bottom: 5px
			// border-bottom: 1px solid $white-ter
			&:last-child
				border-bottom: 0
			&:hover
				// border-bottom-color: transparent
			a,
			.placeholders
				position: relative
				display: flex
				justify-content: space-between
				align-items: center
				padding: spacing(2)
				color: $grey-light
				transition: none
				border-radius: $radius
				background: lighten($white-bis, 10%)
				font:
					size: 1.4em
					weight: $weight-regular
				&:hover
					background: $white-ter
					color: $secondary
				&.active
					background: $white-ter
					font-weight: $weight-semibold
					span
						@extend .gradient-text
					.status
						transform: scale(1.6)
				@include mobile
					padding: spacing(2.5) spacing()
				.status
					display: inline-block
					width: 10px
					height: 10px
					border-radius: 100%
					background: $danger
					margin-left: auto
					transition: $transition-base
					&.online
						background: $success
				.bullet
					position: absolute
					top: -4px
					right: 10px
			.placeholders
				padding: 0 0 0 10px
				position: relative
				top: 7px


	&__chart
		width: auto
		min-height: 300px
		@include desktop
			height: 370px
	&__error
		height: calc(100% - 90px)!important
	&__results
		display: flex
		justify-content: center
		align-items: center
		text-align: center
	&__results,
	&__error
		svg
			width: 50px!important
			opacity: 0.6
			margin: 0 0 15px 0!important

.analytics
	margin: 0
	.column
		padding: 0.25em
	&__box
		margin: 0 !important
		max-width: 100% !important
		display: flex
		justify-content: center
		align-items: center
		.icon
			flex: 0 0 40%
			span
				display: flex
				justify-content: center
				align-items: center
				padding: 15px
				margin: 0 auto
				width: 75px
				height: 75px
				border-radius: 100%
				background: $white-ter
			svg
				width: 100px
				height: auto
		.content
			flex: 0 0 60%
		&.angry
			svg
				fill: #e84118
		&.disgusted
			svg
				fill: #9c88ff
		&.fearful
			svg
				fill: #487eb0
				.st0
					stroke: #487eb0
		&.happy
			svg
				fill: #4cd137
		&.neutral
			svg
				fill: #fbc531
		&.sad
			svg
				fill: #e89c18
		&.surprised
			svg
				fill: #00a8ff
		&.audience
			.icon span
				background: #005fbe
			svg
				fill: #fff
				// stroke-width: 1px
				// stroke: $white-ter
		&.passerby
			.icon span
				background: #505050
			svg
				fill: #fff
		&.screens
			.icon span
				background: $secondary
			svg
				stroke: #fff
	&__value,
	&__total
		margin: 0 !important
		color: $primary !important
		-webkit-text-fill-color: $primary !important
	&__total
		opacity: 0.5
		text-transform: capitalize
		margin-top: -10px !important
	&__bars
		display: flex
		justify-content: flex-start
		align-items: center
		flex-direction: column
		&__item
			position: relative
			width: 100%
			margin-bottom: 20px
			span,
			.progress-value
				font-size: 20px
				font-weight: 700
				color: $primary
			span
				padding: 13px 20px
				position: absolute
				top: 0
				z-index: 10
			.progress-value
				padding: 20px
				left: calc(100% - 40px)
				color: $white
			.progress
				border-radius: 5px
				height: 55px
				border-radius: 5px
				// background: $tertiary
				border: 1px dashed $white-ter


#video-face
	filter: blur(1px) invert(1)
	position: absolute
	left: 50%
	top: 50%
	transform: translate(-50%, -50%)
	border-radius: 10px

.column-video
	margin-top: -10px
	padding: 0 13px
	.dashboard__panel
		overflow: hidden
		min-height: calc(100vh - 360px) !important
	.column
		padding: 3px
