.balloon
  --primary-color:hsla(var(--h, 0), 100%, 50%, 0.8)
  height: 80px
  width: 80px
  background: var(--primary-color)
  border-radius: 50% 50% 50% 50% / 45% 45% 55% 55%
  position: absolute
  top: 50%
  left: calc(var(--x, 50) * 1%)
  z-index: 999
  animation: float calc(var(--d, 1) * 1s) calc(var(--delay, 0) * -1s) infinite linear both

  &:before
    content: ''
    position: absolute
    width: 20%
    height: 30%
    background: blue
    top: 8%
    left: 16%
    border-radius: 50%
    transform: rotate(40deg)
    background: var(--shine)

.balloon__handle
  width: 2%
  height: 60%
  background: var(--chord)
  top: 100%
  left: 50%
  transform: translate(-50%, 0)
  position: absolute

  &:before, &:after
    content: ''
    position: absolute
    height: 5%
    transform: translate(-50%, 0)
    border-radius: 25% / 50%
    left: 50%

  &:before
    top: 0
    background: var(--chord)
    width: 500%

  &:after
    top: 5%
    background: var(--primary-color)
    width: 700%

@keyframes float
  from
    transform: translate(-50%, -50%) translate(0, 100vh)

  to
    transform: translate(-50%, -50%) translate(0, -100vh)