// |------------------------------------------------------
// |------------------------------------------------------
// | Blocks
// |------------------------------------------------------
// |------------------------------------------------------

@use 'sass:math'

.block
	display: flex
	align-items: center
	justify-content: flex-start
	position: relative
	padding: spacing(2) spacing(1.5)
	background: $white
	min-height: 80px
	border-radius: 3px
	box-shadow: $shadow
	border: 1px solid $white
	border-left: 5px solid transparent
	transition: $transition-base
	&::after
		content: ''
		position: absolute
		top: -1px
		left: -5px
		bottom: -1px
		width: 5px
		border-radius: 3px 0 0 3px
		background: linear-gradient(to bottom, $secondary, $tertiary)
	&.image
		flex-direction: column
		align-items: flex-start
		// border-left: 0
	&.inactive
		background: rgba($white, 0.15)
		border-left-color: $grey-lighter!important
	&.placeholder
		pointer-events: none
		&::after
			display: none
	&:hover
		opacity: 1	
		// margin-top: -3px
		// background: $white
		// border-left-color: $secondary!important
		&::after
			background: linear-gradient(to bottom, darken($secondary, 10%), darken($tertiary, 10%))
	
	&__copy
		position: absolute
		top: -13px
		right: 25px
	
	&__id
		padding: 5px 10px
		color: $white
		font-family: monospace
		border-radius: 3px
		user-select: none
		cursor: pointer
		letter-spacing: 0.15em
		@extend .gradient-background
		&.status
			padding: 3px 10px 4px
			position: relative
			top: 0
			right: -10px
			letter-spacing: 0.04em
			font:
				family: $custom-font
				size: 10px
		&.inactive
			background: #d9c53e
		&.expirated
			background: #fd4747
		&.scheduled
			background: #3a56c9

	&__content
		padding: 0 spacing(1.5)
		display: flex
		flex-direction: column
		line-height: 1.2
		width: calc(100% - 50px)
		.image &
			width: 100%
			padding: 0

	&__role
		display: block
		text-indent: -9999px
		width: 8px
		height: 8px
		background: $grey-light
		border-radius: 100%
		border: 1px solid $white

	&__avatar
		display: flex
		color: $white
		align-items: center
		justify-content: center
		background: $grey-lighter
		border-radius: 100%
		font:
			size: 1.2rem
			weight: $weight-semibold
		.b-tooltip
			position: absolute
			top: 0
			right: 5px
			z-index: 3

		.image
			width: 100%

	&__name
		color: $grey
		font-weight: $weight-semibold
		white-space: nowrap
		overflow: hidden
		text-overflow: ellipsis
		&--user
			padding-top: 0.45rem
		.b-skeleton
			margin-top: 0

	&__cover
		position: relative
		border-radius: $radius
		background: $grey-light
		min-height: 100px
		width: 100%
		overflow: hidden
		margin: 0 0 spacing(2) 0
		pointer-events: none
		&:after
			@extend .overlay
			background: linear-gradient(120deg, $secondary, $tertiary)
		.placeholder &,
		.no-cover &
			background: transparent
			&:after
				display: none
		.no-cover &
			// background: $grey-lighter 
			img
				display: block
				border: 2px solid $grey-lighter
				border-radius: 2px
		img
			width: 100%
			min-height: 90px
			object-fit: cover
			position: absolute
			top: 50%
			left: 50%
			transform: translate(-50%, -50%)

	&__email
		text-transform: lowercase
		&.placeholder
			margin-top: -3px

	&__email,
	&__address
		white-space: nowrap
		overflow: hidden
		text-overflow: ellipsis
		color: $grey-light
		font:
			weight: $weight-light
			size: 0.85em
		&.secondary
			margin-top: 3px	

	&__state
		text-transform: uppercase
		font:
			size: 14px
			weight: $weight-light
		color: $grey-lighter
		user-select: none
		padding: spacing(1.5) spacing(1.5) 0 0

	&__status
		margin-right: 25px
		margin-top: 6px
		padding: 0
		svg
			width: 35px

	&__checkbox
		position: absolute
		bottom: -5px
		right: -26px
		opacity: 1!important

	&__dropdown
		position: absolute
		top: 50%
		right: 15px
		margin: -13px 0 0 0
		// z-index: 10
		.image &
			top: 20px
			margin: 0

		.dropdown-content
			width: 100%
			border: 1px solid $white-ter
			.svg
				position: relative
				top: -1px
				margin-right: 3px
				svg
					position: relative
					height: auto
					width: 13px
					// margin-right: 3px
				&.profile,
				&.check
					.fill
						fill: $info
				&.check
					svg
						width: 17px
						left: -3px
				&.profile
					svg
						width: 15px
						left: 0
						top: -1px
				&.criteria
					.fill
						fill: $warning
				&.money
					svg
						width: 15px
					.fill
						fill: $success
				&.edit
					svg
						width: 11px
						margin-left: 2px
						margin-right: 2px
				&.trash
					top: -2px
					svg
						width: 16px
						margin-right: 0
				&.restore
					top: 0
				&.template,
				&.screen
					top: -1px
					svg
						margin-right: 5px
					.fill
						fill: $info
				&.template
					top: 0
					.fill
						fill: $success

		.dropdown-item
			font-weight: $weight-medium

		.dots
			opacity: 0.3
			border: none
			cursor: pointer
			&:hover
				opacity: 0.5
			.image &
				fill: $white
				opacity: 1
				&:hover
					opacity: 1
					fill: $secondary
			.no-cover &
				fill: $secondary
				&:hover
					fill: $primary
