// |------------------------------------------------------
// |------------------------------------------------------
// | Overrides
// |------------------------------------------------------
// |------------------------------------------------------

.name
	font:
		size: 19px
		weight: $weight-bold
	line-height: 1
	padding-top: 5px
	margin-bottom: 0
	color: $black

.title
	color: $primary
	font:
		size: 1.8rem
		weight: $weight-bold
	letter-spacing: -0.04em
	@extend .gradient-text
	span
		color: $primary

.button
	&.filter
		position: absolute
		top: 0
		right: 0
	&.export
		min-width: 100px
		margin: 2px spacing() 0 0
	&.create
		padding: spacing(2) $block-spacing * 1.275
		background: $tertiary
		&:hover
			background: darken($tertiary, 10%)
		> span
			display: flex
			justify-content: center
			align-items: center
			padding-top: 2px
	&.save
		padding: spacing(2) $block-spacing * 2

.button
	border-width: 0.1rem
	font-weight: $weight-semibold
	// span
	// 	position: relative
	// 	top: -1px

.svg
	fill: $primary
	transition: $transition-base
	.fill,
	.rect
		fill: $primary
	.stroke
		stroke: $primary
	&__icon
		position: relative
		width: 25px
		height: 100%
		fill: inherit
		vertical-align: middle
		.criteria &
			width: 21px
			top: -1px
			margin-left: 2px

.vue-advanced-cropper__image
	border-radius: 5px

.vue-swatches
	@include tablet
		margin-top: 3px
	&__trigger
		@include mobile
			border-radius: 2px!important
			width: 100%!important
			height: 15px!important

.vue-swatches,
.vue-swatches__trigger__wrapper
	@include mobile
		width: 100%

.control.has-icons-left .icon,
.control.has-icons-right .icon
	height: 100%

.toast
	small
		display: block

.notices
	z-index: 9999

strong
	color: inherit
	font:
		weight: $weight-semibold

.tooltip-content
	font-weight: $weight-light

.stroke
	stroke: $black

.datepicker .dropdown-content
	box-shadow: 0 0 15px rgb(10 10 10 / 20%)!important
	.field .select
		font-size: 14px
		max-height: 40px
		min-width: 80px
	.datepicker-table .datepicker-body .datepicker-cell.is-today
		color: $secondary
		border-color: $white
		font-weight: $weight-semibold

.multiselect__option--highlight,
.multiselect__option--selected
	font-weight: normal!important

.multiselect__option--selected span
	font-weight: $weight-semibold

.loading-overlay .loading-background
	background: $white-ter

.has-label
	position: relative
	margin-top: 30px

.carousel-list.has-shadow
	box-shadow: none!important

.b-checkbox.checkbox input[type=checkbox] + .check
	background-color: $white
	border-color: $grey-lighter
	box-shadow: none!important

// .control.has-icons-right .icon.is-right
	// right: -5px

.input-helper
	position: absolute
	right: 42px
	top: 44px
	z-index: 10
	background: $white
	padding: 0 10px
	pointer-events: none
	user-select: none

#container,
#editor,
#toolbar
	background: $white
	.wysiwyg-button
		border: 0
#editor
	h1
		font-size: 32px
	h2
		font-size: 28px
	h3
		font-size: 24px
	h4
		font-size: 20px
	h5
		font-size: 18px
	h6
		font-size: 16px
	p, ol, ul
		font-size: 16px
	ol, ul
		padding-left: 15px
