// |------------------------------------------------------
// |------------------------------------------------------
// | Forms
// |------------------------------------------------------
// |------------------------------------------------------

.label
	color: $primary
	font-weight: $weight-semibold
	z-index: 2!important

.field
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus
		outline: none
		box-shadow: 0 0 0 1000px $white inset
		font:
			family: inherit
			size: inherit

	.input
		box-shadow: none
		font-weight: $weight-light
		background: $white
		&:not(.is-small, .is-semibold, .is-large)
			padding:
				top: 1.5em
				bottom: 1.5em
				right: 1.5em

	.select
		display: block
		&:not(.is-multiple, .is-small, .is-semibold, .is-large)
			height: 3em
		&:after
			margin-top: -2px
		select
			width: 100%
			height: 100%

	.help
		display: none
		position: absolute
		color: $white
		background: $danger
		border-radius: 4px
		right: 15px
		bottom: -15px
		white-space: nowrap
		z-index: 10
		&.is-danger,
		&.is-success
			padding: 5px 10px
		&.is-danger
			display: block
			background: $danger
		&.is-success
			background: $success
	.seconds
		.button
			margin-top: 5px

	&.preview
		// margin-top: -10px
		.image
			display: inline-flex
			justify-content: center
			align-items: center
			position: relative
			border: 1px solid $grey-lighter
			background: $white
			overflow: hidden
			border-radius: $radius
			width: 100%
			min-height: 130px
			background: url('./assets/images/no-image.png') center center no-repeat
			&:hover
				border-color: $grey-light
			&.is-valid
				background: $white-ter
				border-color: $success
			img
				display: block
				border-radius: $radius
		.input
			position: absolute
			top: 0
			right: 0
			bottom: 0
			left: 0
			height: 100%
			cursor: pointer
			border: 0
			opacity: 0
			text-indent: -9999px
			transition: $transition-base

.file-cta
	color: $white
	border: 0
	@extend .gradient-background
	.file-label
		font-weight: 600
